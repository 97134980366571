import { ThemeProvider } from '@mui/material';
import { AppContextProvider } from 'context/AppContextProvider';
import React, { lazy, Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Routes } from 'react-router-dom';
import Store from 'views/Store/Store';

import theme from './Theme';

const queryClient = new QueryClient();
const DeviceSelector = lazy(() => import('./views/App/DeviceSelector'));
const AppInterface = lazy(() => import('./views/App/Interface'));
const AccountInterface = lazy(() => import('./views/Account/Account'));
const SearchInterface = lazy(() => import('./views/Search/Search'));
const LoginInterface = lazy(() => import('./views/Authenticate/Login'));
const RegisterInterface = lazy(() => import('./views/Authenticate/Register'));
const LogoutInterface = lazy(() => import('./views/Authenticate/Logout'));
const ForgetInterface = lazy(() => import('./views/Authenticate/Forget'));
const ResetInterface = lazy(() => import('./views/Authenticate/Reset'));
const AddAPOSInterface = lazy(() => import('./views/Static/AddPOS'));
const LandingInterface = lazy(() => import('./views/Static/Landing'));
const CreateItemInterface = lazy(() => import('./views/App/CreateItem'));

function App() {
  return (
    <AppContextProvider>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Suspense>
            <Routes>
              <Route path="/" element={<LandingInterface />} />
              <Route path="search" element={<SearchInterface />} />
              <Route path="login" element={<LoginInterface />} />
              <Route path="logout" element={<LogoutInterface />} />
              <Route path="register" element={<RegisterInterface />} />
              <Route path="password-forget" element={<ForgetInterface />} />
              <Route
                path="password-reset/:uid/:token"
                element={<ResetInterface />}
              />
              <Route path="/my-account" element={<AccountInterface />} />
              <Route path="/device-add" element={<AddAPOSInterface />} />
              <Route path="/app" element={<DeviceSelector />} />
              <Route path="/app/:uuid" element={<AppInterface />} />
              <Route path="/create" element={<CreateItemInterface />} />
              <Route path="/stores" element={<Store />} />
            </Routes>
          </Suspense>
        </QueryClientProvider>
      </ThemeProvider>
    </AppContextProvider>
  );
}

export default App;
