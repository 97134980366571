import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { sendData } from 'services/APIClient';
import { USER_POS_DELETE_URL } from 'services/APIConstants';
import Interceptor from 'services/Interceptor';
import AuthToken, { isLoggedIn } from 'services/Token';

function RemoveStore(props) {
  const [openDialog, setOpenDialog] = useState(false);
  const [inputUUID, setInputUUID] = useState('');
  const [openSnack, setOpenSnack] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');

  const { item } = props;
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDeleteStore = async (data) => {
    if (inputUUID === item.uuid) {
      await sendData(
        USER_POS_DELETE_URL(item.uuid),
        data,
        AuthToken(),
        'delete',
        isLoggedIn() ? Interceptor : null
      );
      setMessage('Store successfully Updated!');
      setOpenSnack(true);
      setSeverity('success');
      window.location.reload();
    } else {
      setMessage('UUID does not match. Please try again.');
      setOpenSnack(true);
      setSeverity('error');
    }
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <Box
        sx={{
          border: '2px solid red',
          padding: 1,
        }}
      >
        <Typography variant="h4" align="center" style={{ lineHeight: '70px' }}>
          REMOVE/UNLINK THIS STORE FROM THIS ACCOUNT
        </Typography>
        <Paper elevation={4} sx={{ padding: 1 }}>
          <Typography variant="subtitle1" align="left">
            Machine Name: {item.machine_name}
          </Typography>
          <Typography
            variant="body2"
            align="left"
            style={{ marginBottom: '10px' }}
          >
            UUID: {item.uuid}
          </Typography>
          <Button
            variant="contained"
            color="error"
            onClick={handleOpenDialog}
            fullWidth
          >
            Remove Store
          </Button>
        </Paper>
      </Box>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Store Removal</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to remove this store?</Typography>
          <br />
          <Typography>
            Machine UUID: <strong>{item.uuid}</strong>
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            label="Type the machine UUID to confirm"
            fullWidth
            variant="outlined"
            value={inputUUID}
            onChange={(e) => setInputUUID(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            onClick={() => {
              handleDeleteStore(item);
            }}
            color="error"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnack}
        autoHideDuration={3000}
        onClose={() => {
          setOpenSnack(false);
        }}
      >
        <Alert severity={severity}>{message}</Alert>
      </Snackbar>
    </div>
  );
}

export default RemoveStore;
