import AddIcon from '@mui/icons-material/Add';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import React from 'react';

const menuList = [
  {
    text: 'About us',
    href: '/who-we-are',
  },
  {
    text: 'What we do',
    href: '/what-we-do',
  },
  {
    text: 'For Businesses',
    href: '/for-businesses',
  },
];

const featuredList = [
  {
    text: 'Quick Sync App',
    href: '/app',
  },
  {
    text: 'Stores',
    href: '/stores',
  },
  {
    text: 'About us',
    href: '/who-we-are',
  },
  {
    text: 'What we do',
    href: '/what-we-do',
  },
  {
    text: 'For Businesses',
    href: '/for-businesses',
  },
];

const drawerMenu = [
  {
    text: 'Home',
    href: '/my-account',
    icon: <HomeIcon color="primary" />,
  },
  {
    text: 'Switch POS/Store',
    href: '/app',
    icon: <StoreMallDirectoryIcon color="primary" />,
  },
  {
    text: 'Add a POS',
    href: '/device-add',
    icon: <AddBusinessIcon color="primary" />,
  },
  {
    text: 'Add an Item',
    href: '/create',
    icon: <AddIcon color="primary" />,
  },
  {
    text: 'Manage POS',
    href: '/manage-pos',
    icon: <MapsHomeWorkIcon color="primary" />,
  },
  {
    text: 'Sign out',
    href: '/logout',
    icon: <LogoutIcon color="primary" />,
  },
];
export default menuList;
export { featuredList, drawerMenu };
