import axios from 'axios';
import { useEffect, useState } from 'react';

import { BASE_URL } from './APIConstants';

const is_dev = process.env.NODE_ENV === 'development';

const requestData = async (endpoint, token = '', interceptor = null) => {
  if (interceptor) {
    interceptor(axios);
  }
  try {
    if (!token) {
      const response = await axios.get(`${BASE_URL}${endpoint}`, {
        withCredentials: !is_dev,
      });
      return response.data;
    } else {
      const options = {
        headers: { Authorization: `Bearer ${token}` },
        withCredentials: !is_dev,
      };
      const response = await axios.get(`${BASE_URL}${endpoint}?`, options);
      return response.data;
    }
  } catch (error) {
    throw Object({ code: error.response.status, message: error.message });
  }
};

const sendData = async (
  endpoint,
  data,
  token = false,
  method = 'put',
  interceptor = null
) => {
  if (interceptor) {
    interceptor(axios, data);
  }
  try {
    if (token) {
      const response = await axios({
        method: method,
        url: `${BASE_URL}${endpoint}`,
        data: data,
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } else {
      const response = await axios({
        method: method,
        url: `${BASE_URL}${endpoint}`,
        data: data,
        withCredentials: !is_dev,
      });
      return response.data;
    }
  } catch (error) {
    throw Object({
      code: error.response.status,
      message: error.message,
      data: error.response.data ? error.response.data : false,
    });
  }
};

const useFetch = (endpoint, token = '', interceptor = null) => {
  const [isFetching, setIsfetching] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const FetchData = async () => {
      try {
        const response = await requestData(endpoint, token, interceptor);
        setData(response);
      } catch (err) {
        console.log(err);
        setError(err);
      } finally {
        setIsfetching(false);
      }
    };
    FetchData();
  }, [endpoint, setData, token, interceptor]);
  return { isFetching, data, error };
};

export { useFetch, requestData, sendData };
