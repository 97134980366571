import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import Drawer from './component/Drawer/Drawer';

export default function ButtonAppBar(props) {
  const navigate = useNavigate();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar>
        <Toolbar sx={{ backgroundColor: '#313131' }}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={
              props.isMain
                ? null
                : () => {
                    navigate(-1);
                  }
            }
          >
            {props.isMain ? <Drawer /> : <ArrowBackIcon fontSize="large" />}
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Quick Sync POS
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
