import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Grid, Typography } from '@mui/material';
import React from 'react';

import FooterStyles from './FooterStyle';

const Footer = () => {
  const { classes } = FooterStyles();

  return (
    <Grid container className={classes.mainGridStyle}>
      <Grid item xs={1} sm={2} />
      <Grid item xs={12} className={classes.socialLinkStyle}>
        <Typography align="center" color="primary">
          <a
            href={`https://www.facebook.com/`}
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon color="primary" />
          </a>
          &nbsp;
          <a href={`https://twitter.com/`} target="_blank" rel="noreferrer">
            <TwitterIcon color="primary" />
          </a>
          &nbsp;
          <a href={`https://instagram.com/`} target="_blank" rel="noreferrer">
            <InstagramIcon color="primary" />
          </a>
        </Typography>
        <Typography variant="subtitle2" align="center">
          A Product of Money Machines Inc.
          <br />
          <br />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
