import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  LinearProgress,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ErrorView from 'components/Error/ErrorView';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useFetch } from 'services/APIClient';
import { USER_STORE_URL } from 'services/APIConstants';
import { DAYS_IN_WEEK, SERVICE_LIST } from 'services/constants';
import Interceptor from 'services/Interceptor';
import AuthToken, { isLoggedIn } from 'services/Token';

import Onboard from './components/Buttons/Onboard';
import Save from './components/Buttons/Save';
import EmptyScreen from './components/EmptyScreen/EmptyScreen';
import RemoveStore from './components/RemoveStore';
import TabPanel from './components/TabPanel';

const Store = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [storeInfo, setStoreInfo] = useState('');
  const [isStoreEnabled, setIsStoreEnabled] = useState(false);

  const [selectedWorkingDays, setSelectedWorkingDays] = useState([]);
  const [selectedWorkingHours, setSelectedWorkingHours] = useState([]);
  const [fieldErrors, setFieldErrors] = useState({});

  const { isFetching, data, error } = useFetch(
    USER_STORE_URL,
    AuthToken(),
    isLoggedIn() ? Interceptor : null
  );

  useEffect(() => {
    if (data !== null && data.results && data.results[currentTab]) {
      setStoreInfo(data.results[currentTab]);
      setIsStoreEnabled(data.results[currentTab].is_enabled);

      const days = data.results[currentTab].working_days;
      const hours = data.results[currentTab].working_hours;

      const workingDays =
        days !== 'NA' && days.constructor === Array ? days : [];

      const workingHours =
        hours !== 'NA' && days.constructor === Array ? hours : [];

      setSelectedWorkingDays(workingDays);
      setSelectedWorkingHours(workingHours);
    }
  }, [data, currentTab]);

  if (isFetching) {
    return (
      <Typography variant="h6" align="top" mt={'0%'}>
        <LinearProgress />
      </Typography>
    );
  }

  if (error) {
    return <ErrorView statusCode={error.code} />;
  }

  if (data === null || !data.results || !data.results[currentTab]) {
    return <EmptyScreen />;
  }

  // Function to check if a specific day is selected for a store
  const isDaySelected = (dayValue) => {
    return selectedWorkingDays.includes(dayValue);
  };
  // Function to return index of selected day
  const getSelectedDayIndex = (dayValue) => {
    return selectedWorkingDays.indexOf(dayValue);
  };

  // Function to toggle the selection of a working day for a store
  const handleWorkingDayToggle = (dayValue) => {
    // Initialize selectedWorkingDays if it's initially undefined or an empty array
    const updatedSelectedWorkingDays = selectedWorkingDays.length
      ? [...selectedWorkingDays]
      : [];

    const index = updatedSelectedWorkingDays.indexOf(dayValue);
    if (index === -1) {
      updatedSelectedWorkingDays.push(dayValue);

      // Initialize selectedWorkingHours if it's initially undefined or an empty array
      setSelectedWorkingHours((prevWorkingHours) => {
        const updatedWorkingHours = prevWorkingHours.length
          ? [...prevWorkingHours]
          : [];

        updatedWorkingHours.push('');
        return updatedWorkingHours;
      });
    } else {
      updatedSelectedWorkingDays.splice(index, 1);

      // Initialize selectedWorkingHours if it's initially undefined or an empty array
      setSelectedWorkingHours((prevWorkingHours) => {
        const updatedWorkingHours = prevWorkingHours.length
          ? [...prevWorkingHours]
          : [];

        updatedWorkingHours.splice(index, 1);
        return updatedWorkingHours;
      });
    }
    setSelectedWorkingDays(updatedSelectedWorkingDays);
  };

  // Function to handle changes in working hours
  const handleWorkingHoursChange = (dayIndex, timeType, value) => {
    const updatedWorkingHours = [...selectedWorkingHours];
    const timeString = updatedWorkingHours[dayIndex].split('-');
    let [startTime, endTime] = timeString;

    if (timeType === 'start') {
      startTime = value.format('hh:mm A');
    } else if (timeType === 'end') {
      endTime = value.format('hh:mm A');
    }

    updatedWorkingHours[dayIndex] = `${startTime}-${endTime}`;
    setSelectedWorkingHours(updatedWorkingHours);
  };

  const handleIsStoreEnabledChange = (event) => {
    setIsStoreEnabled(event.target.checked);
  };

  // // Function to handle tab change
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  let storesData = data.results;

  const updatedStoreInfo = { ...storeInfo, name: storeInfo.alias };

  return (
    <>
      <Header isLoggedIn={isLoggedIn()} />

      <Grid
        item
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '80vh', marginTop: '4rem', p: '3rem' }}
      >
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          variant="fullWidth"
          textColor="primary"
          indicatorColor="primary"
          aria-label="simple tabs"
          sx={{ marginBottom: '2rem' }}
        >
          {storesData.map((store, index) => (
            <Tab
              key={store.id}
              label={store.alias ? store.alias : store.uuid.machine_name}
            />
          ))}
        </Tabs>
        {storesData.map((store, index) => (
          <TabPanel
            key={store.id}
            value={currentTab}
            index={index}
            sx={{ padding: 2 }}
          >
            <Box key={store.id} xs={12} sm={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <Box
                    variant="body2"
                    sx={{
                      textAlign: 'center',
                      color: 'text.secondary',
                      mb: 4,
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isStoreEnabled}
                          onChange={handleIsStoreEnabledChange}
                        />
                      }
                      label="Enable Store in App"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} sm={6}>
                      <Box
                        variant="body2"
                        sx={{
                          textAlign: 'center',
                          color: 'text.secondary',
                          mb: 4,
                        }}
                      >
                        <TextField
                          key={`alias-${store.id}`}
                          label="Store Alias"
                          placeholder="Liquor Store"
                          id="outlined-required"
                          defaultValue={store.alias}
                          InputLabelProps={{
                            style: {
                              fontSize: '1.25rem',
                            },
                          }}
                          InputProps={{
                            style: { fontSize: '1.25rem' },
                          }}
                          onBlur={(e) => {
                            const newAlias = e.target.value;
                            setStoreInfo({ ...storeInfo, alias: newAlias });
                          }}
                          error={!!fieldErrors.alias}
                          helperText={fieldErrors.alias}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <Box
                        variant="body2"
                        sx={{
                          textAlign: 'center',
                          color: 'text.secondary',
                          mb: 4,
                        }}
                      >
                        <TextField
                          label="Address"
                          placeholder="221B Baker Street"
                          defaultValue={store.address}
                          InputLabelProps={{
                            style: {
                              fontSize: '1.25rem',
                            },
                          }}
                          InputProps={{
                            style: { fontSize: '1.25rem' },
                          }}
                          onBlur={(e) => {
                            const newAddress = e.target.value;
                            setStoreInfo({ ...storeInfo, address: newAddress });
                          }}
                          error={!!fieldErrors.address}
                          helperText={fieldErrors.address}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <Box
                        variant="body2"
                        sx={{
                          textAlign: 'center',
                          color: 'text.secondary',
                          mb: 4,
                        }}
                      >
                        <TextField
                          label="City"
                          defaultValue={store.city}
                          InputLabelProps={{
                            style: {
                              fontSize: '1.25rem',
                            },
                          }}
                          InputProps={{
                            style: { fontSize: '1.25rem' },
                          }}
                          onBlur={(e) => {
                            const newCity = e.target.value;
                            setStoreInfo({ ...storeInfo, city: newCity });
                          }}
                          error={!!fieldErrors.city}
                          helperText={fieldErrors.city}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <Box
                        variant="body2"
                        sx={{
                          textAlign: 'center',
                          color: 'text.secondary',
                          mb: 2,
                        }}
                      >
                        <TextField
                          label="State Code"
                          defaultValue={store.state}
                          placeholder="CA"
                          InputLabelProps={{
                            style: {
                              fontSize: '1.25rem',
                            },
                          }}
                          InputProps={{
                            style: { fontSize: '1.25rem' },
                          }}
                          onBlur={(e) => {
                            const newState = e.target.value;
                            setStoreInfo({ ...storeInfo, state: newState });
                          }}
                          error={!!fieldErrors.state}
                          helperText={fieldErrors.state}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <Box
                        variant="body2"
                        sx={{
                          textAlign: 'center',
                          color: 'text.secondary',
                          mb: 4,
                        }}
                      >
                        <TextField
                          label="Zip Code"
                          defaultValue={store.zip_code}
                          InputLabelProps={{
                            style: {
                              fontSize: '1.25rem',
                            },
                          }}
                          InputProps={{
                            style: { fontSize: '1.25rem' },
                          }}
                          onBlur={(e) => {
                            const newZip = e.target.value;
                            setStoreInfo({ ...storeInfo, zip_code: newZip });
                          }}
                          error={!!fieldErrors.zip_code}
                          helperText={fieldErrors.zip_code}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <Box
                        variant="body2"
                        sx={{
                          textAlign: 'center',
                          color: 'text.secondary',
                          mb: 4,
                        }}
                      >
                        <TextField
                          label="Phone"
                          defaultValue={store.phone}
                          InputLabelProps={{
                            style: {
                              fontSize: '1.25rem',
                            },
                          }}
                          InputProps={{
                            style: { fontSize: '1.25rem' },
                          }}
                          onBlur={(e) => {
                            const newPhone = e.target.value;
                            setStoreInfo({ ...storeInfo, phone: newPhone });
                          }}
                          error={!!fieldErrors.phone}
                          helperText={fieldErrors.phone}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={false} sm={1} md={1} />
                    <Grid item xs={12} sm={10} md={10} justifyItems="center">
                      <Paper
                        variant="outlined"
                        sx={{
                          padding: '16px',
                          marginBottom: '16px',
                          position: 'relative',
                          '&::before': {
                            content: '"Working Schedules"',
                            position: 'absolute',
                            top: '-12px',
                            left: '2%',
                            width: '170px',
                            height: '16px',
                            color: '#6c6d74',
                            fontSize: '1.1rem',
                            backgroundColor: 'white',
                            fontFamily: 'Poppins',
                          },
                        }}
                      >
                        <FormGroup>
                          {fieldErrors.working_days && (
                            <Typography
                              color="error"
                              variant="caption"
                              sx={{ mt: 1 }}
                            >
                              {fieldErrors.working_days}
                            </Typography>
                          )}
                          <Grid container spacing={2}>
                            {DAYS_IN_WEEK.map((day, dayIndex) => (
                              <Grid item key={day.value} xs={12} sm={6} md={6}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={isDaySelected(day.value)}
                                      onChange={() =>
                                        handleWorkingDayToggle(day.value)
                                      }
                                    />
                                  }
                                  label={day.name}
                                />
                                {isDaySelected(day.value) && (
                                  <Grid container spacing={1}>
                                    <Grid item xs={6} sx={{ mb: 2 }}>
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <TimePicker
                                          label="Start Time"
                                          value={
                                            selectedWorkingHours[
                                              getSelectedDayIndex(day.value)
                                            ]
                                              ? dayjs(
                                                  selectedWorkingHours[
                                                    getSelectedDayIndex(
                                                      day.value
                                                    )
                                                  ].split('-')[0],
                                                  'hh:mm A'
                                                )
                                              : null
                                          }
                                          onChange={(time) =>
                                            handleWorkingHoursChange(
                                              getSelectedDayIndex(day.value),
                                              'start',
                                              time
                                            )
                                          }
                                          ampm
                                        />
                                      </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <TimePicker
                                          label="End Time"
                                          value={
                                            selectedWorkingHours[
                                              getSelectedDayIndex(day.value)
                                            ]
                                              ? dayjs(
                                                  selectedWorkingHours[
                                                    getSelectedDayIndex(
                                                      day.value
                                                    )
                                                  ].split('-')[1],
                                                  'hh:mm A'
                                                )
                                              : null
                                          }
                                          onChange={(time) =>
                                            handleWorkingHoursChange(
                                              getSelectedDayIndex(day.value),
                                              'end',
                                              time
                                            )
                                          }
                                          ampm
                                        />
                                      </LocalizationProvider>
                                    </Grid>
                                  </Grid>
                                )}
                              </Grid>
                            ))}
                          </Grid>
                        </FormGroup>
                      </Paper>
                    </Grid>
                    <Grid item xs={false} sm={1} md={1} />
                    <Grid item xs={false} sm={1} md={1} />
                    <Grid item xs={12} sm={10} md={10}>
                      <Paper
                        variant="outlined"
                        style={{ textAlign: 'center', padding: 10 }}
                      >
                        {SERVICE_LIST.map((service, index) => (
                          <img
                            key={index}
                            src={service.img}
                            alt={service.label}
                            style={{
                              width: '80px',
                              height: 'auto',
                              margin: '5px',
                              borderRadius: '5px',
                              padding: '0rem',
                              opacity: store[service.key] ? '1' : '0.5',
                            }}
                          />
                        ))}
                        <br />
                        <Onboard
                          item={{
                            ...storesData[currentTab],
                            ...updatedStoreInfo,
                            working_days: selectedWorkingDays,
                            working_hours: selectedWorkingHours,
                          }}
                        />
                      </Paper>
                      <br />
                      <Save
                        item={{
                          ...storesData[currentTab],
                          ...storeInfo,
                          ...updatedStoreInfo,
                          is_enabled: isStoreEnabled,
                          working_days: selectedWorkingDays,
                          working_hours: selectedWorkingHours,
                        }}
                        setFieldErrors={setFieldErrors}
                      />
                      <RemoveStore
                        item={{ ...storesData[currentTab]['uuid'] }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
        ))}
      </Grid>
      <Footer />
    </>
  );
};

export default Store;
