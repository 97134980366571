import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import MyAppBar from 'components/AppBar/AppBar';
import React from 'react';

const EmptyScreen = (props) => {
  return (
    <Grid container mt={'30vh'}>
      <Grid xs={12}>
        <MyAppBar isMain={true} />
      </Grid>
      <Grid xs={12}>
        <>
          <Typography variant="h5" align="center">
            There is no store information available.
          </Typography>
          <Typography variant="body1" align="center">
            Please make sure that syncer app is running on your device
          </Typography>
        </>
      </Grid>

      <Grid xs={12}>
        <Typography variant="h5" align="center">
          <br />
          <br />
          <Button variant="outlined" href="/device-add">
            Learn more
          </Button>
          <br />
        </Typography>
      </Grid>
    </Grid>
  );
};
export default EmptyScreen;
