import { createTheme } from '@mui/material/styles';

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#232853',
    },
    secondary: {
      main: '#70933e',
    },
  },
  typography: {
    fontFamily: 'Work Sans, Poppins',
    h1: {
      fontFamily: 'Work Sans',
    },
    h2: {
      fontFamily: 'Lexend Deca',
    },
    h3: {
      fontFamily: 'Work Sans',
    },
    h4: {
      fontFamily: 'Work Sans',
    },
    body1: {
      fontFamily: 'Poppins',
    },
    body2: {
      fontFamily: 'Poppins',
    },
    subtitle2: {
      fontFamily: 'Poppins',
    },
    overline: {
      fontFamily: 'Poppins',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 20,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 10,
      },
    },
    MuiGridListTile: {
      root: {
        margin: '0px 4px',
      },
    },
  },
});

export default customTheme;
