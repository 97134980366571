import { makeStyles } from 'tss-react/mui';

const ErrorStyle = makeStyles()((theme) => ({
  viewContainerStyle: {
    minHeight: '70vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '2rem 0 2rem 0',
  },
  containerStyle: {
    position: 'relative',
  },
  errorMessageStyle: {
    margin: '0 2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  errorOverlayStyle: {
    position: 'absolute',
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    zIndex: '1',
  },
  childrenStyle: {
    position: 'relative',
    filter: 'blur(8px)',
  },
  primaryColorStyle: {
    color: theme.palette.primary.main,
  },
}));

export default ErrorStyle;
