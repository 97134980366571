import { makeStyles } from 'tss-react/mui';

const FooterStyle = makeStyles()((theme) => ({
  mainGridStyle: {
    borderTop: '1px solid #c8c8c8',
    padding: '2rem 0',
  },
  titleCase: {
    textTransform: 'capitalize',
  },
  linkStyle: {
    textDecoration: 'none',
    color: 'black',
  },
  socialLinkStyle: {
    maxHeight: '1em',
    textAlign: 'center',
  },
  buttonStyle: {
    margin: '10px 0px',
  },
  verticalAlign: {
    display: 'inline-flex',
  },
}));

export default FooterStyle;
