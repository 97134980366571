import { DEV_AUTH_TOKEN_KEY, DEV_REFRESH_TOKEN_KEY } from './APIConstants';

const is_dev = process.env.NODE_ENV === 'development';
const LOGIN_FLAG = 'LOGGED_IN_FLAG';

// Main methods to retrieve tokens or `False` in case of prod builds
// Follow component naming conventions
const AuthToken = () => {
  if (is_dev) {
    return localStorage.getItem(DEV_AUTH_TOKEN_KEY);
  }

  return false;
};

const RefreshToken = () => {
  if (is_dev) {
    return localStorage.getItem(DEV_REFRESH_TOKEN_KEY);
  }
  return false;
};

// Helper methods to set/update or remove tokens
// Follow method naming conventions
const setAuthToken = (token) => {
  if (is_dev) {
    localStorage.setItem(DEV_AUTH_TOKEN_KEY, token);
  }
};

const setRefreshToken = (token) => {
  if (is_dev) {
    localStorage.setItem(DEV_REFRESH_TOKEN_KEY, token);
  }
};

const isLoggedIn = () => {
  return localStorage.getItem(LOGIN_FLAG) === '_';
};

const removeLoginFlag = () => {
  localStorage.removeItem(DEV_AUTH_TOKEN_KEY);
  localStorage.removeItem(DEV_REFRESH_TOKEN_KEY);
  localStorage.removeItem(LOGIN_FLAG);
};

const setLoginFlag = () => {
  return localStorage.setItem(LOGIN_FLAG, '_');
};

export default AuthToken;
export {
  AuthToken,
  RefreshToken,
  setAuthToken,
  setRefreshToken,
  isLoggedIn,
  removeLoginFlag,
  setLoginFlag,
};
