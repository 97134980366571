import { ExpandMore } from '@mui/icons-material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import InstagramIcon from '@mui/icons-material/Instagram';
import LoginIcon from '@mui/icons-material/Login';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import {
  AppBar,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Menu,
  Paper,
  Slide,
  Toolbar,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import img from 'res/logo.svg';
import { featuredList } from 'services/header';

import HeaderStyles from './HeaderStyle';

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

/**
 * A Contextual Header that changes based on login status of a user
 *
 * @visibleName Header
 */
const Header = (props) => {
  const { classes } = HeaderStyles();
  const { isLoggedIn } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const CreateMenu = () => {
    return (
      <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="left"
          spacing={2}
          className={classes.popOverMenuStyle}
        >
          <Grid item xs={12} sm={12}>
            <Paper variant="outlined" className={classes.menuItemStyle}>
              {isLoggedIn ? (
                <>
                  <Button
                    color="primary"
                    href="/my-account"
                    startIcon={<LoginIcon />}
                  >
                    My Account
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    color="primary"
                    endIcon={<ExitToAppIcon />}
                    size="medium"
                    href="/login"
                  >
                    Login
                  </Button>
                  -
                  <Button color="primary" href="/register">
                    {' '}
                    Signup
                  </Button>
                </>
              )}
            </Paper>
            <br />
            <Paper variant="outlined" className={classes.menuItemStyle}>
              <List>
                <Link
                  to="/listing/currently-in-season"
                  className={`${classes.menuLinkStyle} ${classes.smOnlyStyle}`}
                >
                  <Divider />
                </Link>

                {featuredList.map((menuItem, index) => {
                  return (
                    <Link
                      key={index}
                      to={menuItem.href}
                      className={classes.menuLinkStyle}
                    >
                      <ListItem alignItems="center" key={index}>
                        <ListItemText primary={menuItem.text} />
                      </ListItem>
                      {index < featuredList.length - 1 ? <Divider /> : null}
                    </Link>
                  );
                })}
              </List>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper variant="outlined" className={classes.menuItemStyle}>
              {isLoggedIn ? (
                <Button
                  color="primary"
                  startIcon={<ExitToAppIcon />}
                  size="medium"
                  href="/logout"
                  style={{ width: '90%' }}
                >
                  Sign out
                </Button>
              ) : (
                <>
                  <Typography align="center" color="primary">
                    <a
                      href={`https://www.facebook.com/`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FacebookIcon color="primary" />
                    </a>
                    &nbsp;
                    <a
                      href={`https://twitter.com/`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <TwitterIcon color="primary" />
                    </a>
                    &nbsp;
                    <a
                      href={`https://instagram.com/`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <InstagramIcon color="primary" />
                    </a>
                  </Typography>
                </>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Menu>
    );
  };

  return (
    <HideOnScroll {...props}>
      <AppBar
        position="fixed"
        color="transparent"
        className={classes.noShadowStyle}
      >
        <Toolbar>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              xs={4}
              className={`${classes.toolbarLgLinkStyle} ${classes.leftAlign} `}
            >
              <Button
                href={'/discover'}
                onClick={handleClick}
                endIcon={<ExpandMore />}
                startIcon={
                  <FiberManualRecordIcon size="small" color="primary" />
                }
              >
                <b>Quick Sync POS</b>
              </Button>
            </Grid>

            <Grid
              item
              xs={4}
              className={`${classes.toolbarSmLinkStyle} ${classes.leftAlign}`}
            >
              <IconButton onClick={handleClick}>
                <MenuRoundedIcon color="primary" fontSize="large" />
              </IconButton>
            </Grid>
            <Grid item xs={4}>
              <Link
                to="/"
                className={`${classes.linkStyle} ${classes.centerAlign}`}
              >
                <img src={img} alt="header" className={classes.imgStyle} />
              </Link>
            </Grid>
            {!isLoggedIn ? (
              <>
                <Grid
                  item
                  xs={4}
                  className={`${classes.toolbarSmLinkStyle} ${classes.rightAlign}`}
                >
                  <IconButton href="/login">
                    <LoginIcon color="primary" fontSize="large" />
                  </IconButton>
                </Grid>
                <Grid
                  item
                  xs={4}
                  className={`${classes.toolbarLgLinkStyle} ${classes.rightAlign}`}
                >
                  <Button
                    href="/login"
                    endIcon={<LoginIcon color="primary" fontSize="large" />}
                  >
                    <b>Login</b>
                  </Button>
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  item
                  xs={4}
                  className={`${classes.toolbarLgLinkStyle} ${classes.rightAlign}`}
                >
                  {isLoggedIn ? (
                    <Button
                      href="/app"
                      endIcon={
                        <ExitToAppIcon color="primary" fontSize="large" />
                      }
                    >
                      <b>Open App</b>
                    </Button>
                  ) : (
                    <Button
                      href="/login"
                      endIcon={<LoginIcon color="primary" fontSize="large" />}
                    >
                      <b>Login</b>
                    </Button>
                  )}
                </Grid>

                <Grid
                  item
                  xs={4}
                  className={`${classes.toolbarSmLinkStyle} ${classes.rightAlign}`}
                >
                  {isLoggedIn ? (
                    <ExitToAppIcon color="primary" fontSize="large" />
                  ) : (
                    <LoginIcon color="primary" fontSize="large" />
                  )}
                </Grid>
              </>
            )}
          </Grid>
          <CreateMenu />
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
};

Header.propTypes = {
  /** Optional parameter to switch between logged in and logged out view */
  isLoggedIn: PropTypes.bool,
};

export default Header;
