import { makeStyles } from 'tss-react/mui';

const HeaderStyle = makeStyles()((theme) => ({
  imgStyle: {
    height: '3rem',
    [theme.breakpoints.down('md')]: {
      height: '2.2rem',
    },
    [theme.breakpoints.down('xs')]: {
      height: '1.5rem',
    },
  },
  noShadowStyle: {
    backgroundColor: 'white',
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0),0px 4px 5px 0px rgba(0, 0, 0, 0.04),0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
  },
  typographyStyle: {
    flex: '1',
  },
  toolbarSpacingStyle: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
  },
  toolbarSmLinkStyle: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
    textDecoration: 'none',
  },
  toolbarLgLinkStyle: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    textDecoration: 'none',
  },
  linkStyle: {
    textDecoration: 'none',
    display: 'flex',
    margin: '0px 5px',
  },
  smOnlyStyle: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  centerAlign: {
    justifyContent: 'center',
  },
  popOverMenuStyle: {
    width: '20vw',
    minHeight: '45vh',
    overflowY: 'scroll',
    padding: 10,
    [theme.breakpoints.down('md')]: {
      width: '80vw',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      minHeight: '80vh',
    },
    backgroundColor: '#edeff2',
  },
  menuItemStyle: {
    padding: 10,
    textAlign: 'center',
    maxWidth: '80vw',
    [theme.breakpoints.down('sm')]: {
      overflowY: 'scroll',
    },
  },
  menuLinkStyle: {
    textDecoration: 'none',
    color: 'black',
  },
  leftAlign: {
    justifyContent: 'flex-start',
    textAlign: 'start',
  },
  rightAlign: {
    justifyContent: 'flex-end',
    textAlign: 'end',
  },
}));

export default HeaderStyle;
