import React, { createContext, useContext, useState } from 'react';
import { LS_POS_LIST } from 'services/APIConstants';
import useLocalStorage from 'services/LocalStorageHook';

export const AppContext = createContext();

export const AppContextProvider = (props) => {
  const [pos, setPos] = useLocalStorage(LS_POS_LIST, {});
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [severity, setSeverity] = useState('success');

  return (
    <AppContext.Provider
      value={{
        pos,
        setPos,
        snackbarMessage,
        setSnackbarMessage,
        snackbarOpen,
        setSnackbarOpen,
        severity,
        setSeverity,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  const appContext = useContext(AppContext);

  if (appContext === undefined) {
    throw new Error('useAppContext must be used within a AppContextProvider');
  }

  return appContext;
};

export default useAppContext;
