import bevzImg from 'res/BEVZ.png';
import doordashImg from 'res/DOORDASH.png';
import grubhubImg from 'res/GRUBHUB.png';
import uberImg from 'res/UBEREATS.png';

const DEF_BUTTONS = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'backspace',
  '0',
];

const CHECKBOXES = [
  { name: 'Tax 1', id: 'tax_1' },
  { name: 'Tax 2', id: 'tax_2' },
  { name: 'Tax 3', id: 'tax_3' },
  { name: 'Check 21', id: 'check_21' },
  { name: 'Liability', id: 'liability' },
  { name: 'Food Stamp', id: 'food_stamp' },
];

const DAYS_IN_WEEK = [
  { name: 'Sun', value: 1 },
  { name: 'Mon', value: 2 },
  { name: 'Tue', value: 3 },
  { name: 'Wed', value: 4 },
  { name: 'Thu', value: 5 },
  { name: 'Fri', value: 6 },
  { name: 'Sat', value: 7 },
];

const SERVICE_LIST = [
  { key: 'uber', label: 'Uber', img: uberImg },
  { key: 'grubhub', label: 'Grubhub', img: grubhubImg },
  { key: 'bevz', label: 'Bevz', img: bevzImg },
  { key: 'doordash', label: 'Doordash', img: doordashImg },
];

export default DEF_BUTTONS;

export { CHECKBOXES, DAYS_IN_WEEK, SERVICE_LIST };
