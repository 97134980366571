import { BASE_URL, REFRESH_TOKEN_URL } from './APIConstants';
import { RefreshToken, removeLoginFlag, setAuthToken } from './Token';

const is_dev = process.env.NODE_ENV === 'development';

const Interceptor = (axios_, data = {}) => {
  axios_.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      const orignalConfig = error.config;
      if (
        error.response.status === 401 &&
        orignalConfig.url.indexOf(REFRESH_TOKEN_URL) === -1
      ) {
        const res = await axios_.post(
          `${BASE_URL}${REFRESH_TOKEN_URL}`,
          is_dev
            ? {
                refresh: RefreshToken(),
              }
            : {}
        );
        if (res.status === 200 || res.status === 201) {
          if (is_dev) {
            setAuthToken(res.data.access);
          }
          return axios_({
            method: orignalConfig.method,
            url: orignalConfig.url,
            headers: { Authorization: `Bearer ${res.data.access}` },
            withCredentials: !is_dev,
            data: data,
          });
        } else {
          removeLoginFlag();
        }
      }
      return Promise.reject(error);
    }
  );
};

export default Interceptor;
