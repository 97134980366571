import { ErrorOutline } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import React from 'react';
import { isLoggedIn } from 'services/Token';

import ErrorStyles from './ErrorStyle';

const ErrorView = (props) => {
  const { statusCode, height, redirectTo } = props;
  const { classes } = ErrorStyles({ height: height });
  return (
    <>
      <Header isLoggedIn={isLoggedIn()} />
      <div className={classes.viewContainerStyle}>
        <ErrorOutline
          color="primary"
          fontSize="large"
          style={{ margin: '1rem' }}
        />
        <div className={classes.errorMessageStyle}>
          {statusCode === 400 || statusCode === 401 ? (
            <Typography variant="h6" align="center">
              Please login to continue
              <br />
              <Button
                variant="contained"
                disableElevation
                color="primary"
                href={redirectTo ? `/login?redirect=${redirectTo}` : `/login`}
                style={{ textDecoration: 'none' }}
              >
                Sign in
              </Button>
            </Typography>
          ) : (
            <>
              <Typography variant="h6">Error Occurred!</Typography>
              <Typography variant="h6">
                Status Code:{' '}
                <span className={classes.primaryColorStyle}>{statusCode}</span>{' '}
              </Typography>
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ErrorView;
