import MenuIcon from '@mui/icons-material/Menu';
import { Divider, ListItemIcon, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import React, { useState } from 'react';
import logo from 'res/logo.svg';
import { APP_VERSION } from 'services/APIConstants';
import { drawerMenu } from 'services/header';

export default function Drawer(props) {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <MenuIcon
        fontSize="large"
        onClick={() => {
          setOpen(true);
        }}
      />
      <SwipeableDrawer
        anchor="left"
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => {
            setOpen(false);
          }}
        >
          <Box sx={{ borderBottom: '1px solid #808080', textAlign: 'center' }}>
            <img src={logo} style={{ width: '150px', margin: 'auto' }} />
            <Typography variant="body2" align="center">
              Quick Sync POS
            </Typography>
            <Typography variant="subtitle1" align="center">
              Version {APP_VERSION}
            </Typography>
          </Box>
          <List>
            {drawerMenu.map((data, index) => (
              <div key={index}>
                <ListItem disablePadding>
                  <ListItemButton href={data.href}>
                    <ListItemIcon>{data.icon ? data.icon : ''}</ListItemIcon>
                    <ListItemText primary={data.text} />
                  </ListItemButton>
                </ListItem>
                <Divider />
              </div>
            ))}
          </List>
        </Box>
      </SwipeableDrawer>
    </div>
  );
}
